<template>
  <div id="help-index">
    <Headline />
    <HelpIndex />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import HelpIndex from '../../components/help/HelpIndex'

export default {
  components: {
    Headline,
    HelpIndex
  }
}
</script>

<style lang="sass">
.theme-default
  #help-index
    a
      color: #000000
</style>
