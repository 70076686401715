<template>
  <div id="swagger-ui"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui'

export default {
  components: {},
  props: {
    apiUrl: {
      type: String,
      default: ''
    },
    apiToken: {
      type: String,
      default: ''
    }
  },
  mounted: function() {
    const ui = SwaggerUI({
      url: this.apiUrl,
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      onComplete: () => {
        ui.preauthorizeApiKey('bearerAuth', this.apiToken)
      },
      requestInterceptor: req => {
        // needed to authorize call to api.json
        req.headers['Authorization'] = 'Bearer ' + this.apiToken
        return req
      },
      presets: [SwaggerUI.presets.apis]
    })
  }
}
</script>

<style>
@import '~@/../node_modules/swagger-ui/dist/swagger-ui.css';

#help-index .download_btn {
  display: inline !important;
}

#swagger-ui .information-container,
#swagger-ui .scheme-container {
  display: none;
}

#swagger-ui button.locked {
  display: none;
}

.swagger-ui .wrapper {
  padding: 0;
}

.swagger-ui .parameters-col_name {
  padding: 10px !important;
}

.swagger-ui .responses-header,
.swagger-ui .response-col_description {
  font-size: 14px;
}

.swagger-ui .table-container {
  padding: 0;
}

@media only screen and (max-width: 576px) {
  .swagger-ui .wrapper .block-desktop {
    padding-left: 0;
    padding-right: 0;
  }
  .swagger-ui .parameters-col_description input[type='text'] {
    width: 85%;
  }
  .swagger-ui .opblock .opblock-summary-path {
    max-width: 100%;
  }
  .swagger-ui .opblock .opblock-summary {
    flex-direction: column;
  }
  .swagger-ui .opblock .opblock-section-request-body .opblock-section-header,
  .swagger-ui .opblock .response-col_links,
  .swagger-ui .opblock .response-controls,
  .swagger-ui .parameters-col_description input[type='text'],
  .swagger-ui .parameters-col_description select {
    margin: 1em auto;
    min-width: 130px;
  }

  .swagger-ui .content-type {
    max-width: 180px;
  }
}
</style>

<style lang="sass">
@import '~@/sass/variables.sass'

#help-index .download_btn a
  color: $primary !important
</style>
