<template>
  <div>
    <p>
      On this page you can
      <DownloadLink class="download_btn" url="/ui/docs/api.yaml" link-text="download" file-name="api.yaml" :link-as-button="false" />
      the documentation for our new APIv5. API is an application programming interface which is used for creating, updating, reading and deleting
      database entries.<br />
      You can use e.g. Postman to import the api.yaml. All you have to do is create a baseURL environment variable with the specific domain.
    </p>
    <Swagger :api-url="this.apiYamlUrl" :api-token="this.$store.state.auth.access_token" />
  </div>
</template>

<script>
import DownloadLink from '../../components/others/DownloadLink'
import Swagger from '../../components/others/Swagger'

export default {
  components: {
    DownloadLink,
    Swagger
  },
  data() {
    return {
      apiYamlUrl: process.env.VUE_APP_API_BASE_URL + '/ui/docs/api.yaml'
    }
  }
}
</script>
